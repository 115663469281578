/* eslint-disable */
export const updateObjectBySection = <T extends Record<string, any>>(
  obj: T,
  section: string,
  payload: any,
  replaceAll = true
): T => {
  const keys = section.split('/');

  const updatedObject: T = { ...obj };

  let currentLevel: Record<string, any> = updatedObject;

  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];

    if (!currentLevel[key]) {
      currentLevel[key] = {};
    }

    currentLevel[key] = { ...currentLevel[key] };

    currentLevel = currentLevel[key] as Record<string, any>;
  }

  const lastKey = keys[keys.length - 1];
  if (replaceAll) {
    currentLevel[lastKey] = payload;
  } else {
    currentLevel[lastKey] = { ...currentLevel[lastKey], ...payload };
  }

  return updatedObject;
};
