type NestedObject<T> = {
  [K in keyof T]: T[K] extends object ? NestedObject<T[K]> : T[K];
};

export const cleanObject = <T extends NestedObject<T>>(obj: T): Partial<T> =>
  (Object.keys(obj) as (keyof T)[])
    .filter(
      (key) =>
        obj[key] !== null && // Vérifie si la valeur est null
        obj[key] !== undefined && // Vérifie si la valeur est undefined
        (typeof obj[key] !== 'string' || obj[key].trim() !== '') && // Supprime les chaînes de caractères vides
        (typeof obj[key] !== 'object' || Object.keys(obj[key] as NonNullable<unknown>).length > 0) && // Supprime les objets vides
        (!Array.isArray(obj[key]) || (Array.isArray(obj[key]) && obj[key].length > 0)) && // Supprime les tableaux vides
        !key.toString().startsWith('_') // Ignore les clés qui commencent par un underscore
    )
    .reduce<Partial<T>>((acc, key) => {
      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        const cleanedNestedObject = cleanObject(obj[key] as NestedObject<T[typeof key]>);
        if (Object.keys(cleanedNestedObject).length > 0) {
          acc[key] = cleanedNestedObject as T[typeof key];
        }
      } else {
        acc[key] = obj[key];
      }
      return acc;
    }, {});
