import { Injectable } from '@angular/core';
import { ApiServiceBase } from '@api/abstracts';
import { App } from '@api/endpoints/app/models';

@Injectable({ providedIn: 'root' })
export class AppApiService extends ApiServiceBase<App> {
  constructor() {
    super('app');
  }
}
