import { inject, Injectable, signal } from '@angular/core';
import { ApiServiceBase } from '@api/abstracts';
import { EDecouverte, Footer, Price, SectionType } from '@api/endpoints/edecouverte/models';
import { DataService } from '@shared/services';
import { LogsService } from '@shared/services/logs';
import { updateObjectBySection } from '@shared/utils';
import { map, mergeMap, Observable, of, take, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EDecouverteService extends ApiServiceBase<EDecouverte> {
  #eDecouverte = signal<EDecouverte>({} as EDecouverte);
  eDecouverte = this.#eDecouverte.asReadonly();
  readonly #dataService = inject(DataService);

  constructor() {
    super('edecouverte');
  }

  setEdecouverte(edecouverte: EDecouverte): void {
    this.#dataService.set('current-edec', `edec-${edecouverte.edecId}`).pipe(take(1)).subscribe();
    this.#dataService.set(`edec-${edecouverte.edecId}`, edecouverte).pipe(take(1)).subscribe();
    this.#eDecouverte.set(edecouverte);
  }

  loadEdecSaved(): Observable<boolean> {
    return this.#dataService.get$('current-edec').pipe(
      mergeMap((currentEdec) => {
        if (currentEdec) {
          return this.#dataService.get$<Observable<boolean>>(currentEdec as string).pipe(
            tap((edecouverte: unknown) => {
              if (edecouverte) this.#eDecouverte.set(edecouverte as EDecouverte);
            }),
            map((edecouverte) => !!edecouverte)
          );
        } else {
          return of(false);
        }
      })
    );
  }

  updateKitchenerAndStoreId(body: { kitchenerId: string; storeId: string }): Observable<void> {
    return this.customRequest<void>({
      method: 'PATCH',
      body,
    });
  }

  updateEdecouverte<T>(payload: T, section: SectionType): Observable<boolean> {
    const edecId = this.#eDecouverte().edecId;
    const edec = this.#eDecouverte();
    return this.customRequest<Price>({
      method: 'PUT',
      url: `${edecId}/${section}`,
      body: payload,
    }).pipe(
      tap((newPrice) => {
        const footer: Footer = {
          prices: newPrice,
        };
        const updateEdec = updateObjectBySection(edec, section, payload);
        const newEdec = {
          ...updateEdec,
          ...{ footer },
        };
        this.setEdecouverte(newEdec);
        LogsService.info(`🟢 eDecouverte successfully updated`, newEdec);
      }),
      catchError((error: unknown) => {
        LogsService.error(`🔴 eDecouverte update failed`, { report: error });
        return throwError(() => error);
      }),
      map(() => true)
    );
  }

  updateFinishedSubtasks(action: 'ADD' | 'REMOVE', taskId: string): Observable<boolean> {
    let appState = this.eDecouverte().appState;

    if (!appState) {
      appState = {
        finishedSubtasks: [],
      };
    } else if (!appState.finishedSubtasks) {
      appState = { ...appState, finishedSubtasks: [] };
    }

    const finishedSubtasksSet = new Set<string>(appState.finishedSubtasks);

    if (action === 'ADD') {
      LogsService.info(`🤖 La tâche "${taskId}" a été ajoutée à la liste des tâches terminées`);
      finishedSubtasksSet.add(taskId);
    } else if (action === 'REMOVE') {
      LogsService.info(`🤖 La tâche "${taskId}" a été retirée de la liste des tâches terminées`);
      finishedSubtasksSet.delete(taskId);
    }

    appState.finishedSubtasks = Array.from(finishedSubtasksSet);

    return this.updateEdecouverte(appState, 'appState');
  }
}
