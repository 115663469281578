import { inject, Injectable, signal } from '@angular/core';
import { AppApiService } from '@api/endpoints/app';
import {
  App,
  Approach,
  CustomerSearch,
  DiningArea,
  Footer,
  GetInTouch,
  HouseholdComposition,
  ImageSimple,
  KitchenOtherActivities,
  KitchenStorage,
  MenuHeaderPicto,
  MenuSidebar,
  Plan,
  PrepareTheMeal,
  Priorities,
  Project,
  Satellite,
  YourProject,
  YourRequirements,
} from '@api/endpoints/app/models';
import { AuthService } from '@shared/services/auth/auth.service';
import { LogsService } from '@shared/services/logs';
import { map, Observable, of } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';

const TEN_HOURS = 36_000;

@Injectable({
  providedIn: 'root',
})
export class AppInterfaceService {
  readonly #appApiService = inject(AppApiService);
  readonly #authService = inject(AuthService);

  #appInterfaceLoaded = signal<boolean>(false);
  appInterfaceLoaded = this.#appInterfaceLoaded.asReadonly();

  #appInterface = signal<App>({} as App);
  appInterface = this.#appInterface.asReadonly();

  #classColorActive = signal<string>(null!);
  classColorActive = this.#classColorActive.asReadonly();

  #logo = signal<ImageSimple>({} as ImageSimple);
  logo = this.#logo.asReadonly();
  #screenImageLogin = signal<ImageSimple>({} as ImageSimple);
  screenImageLogin = this.#screenImageLogin.asReadonly();
  #customerSearch = signal<CustomerSearch>({} as CustomerSearch);
  customerSearch = this.#customerSearch.asReadonly();
  #householdComposition = signal<HouseholdComposition>({} as HouseholdComposition);
  householdComposition = this.#householdComposition.asReadonly();
  #menuHeaderPicto = signal<MenuHeaderPicto>({} as MenuHeaderPicto);
  menuHeaderPicto = this.#menuHeaderPicto.asReadonly();
  #menuSidebar = signal<MenuSidebar>({} as MenuSidebar);
  menuSidebar = this.#menuSidebar.asReadonly();
  #footer = signal<Footer>({} as Footer);
  footer = this.#footer.asReadonly();
  #yourProject = signal<YourProject>({} as YourProject);
  yourProject = this.#yourProject.asReadonly();
  #getInTouch = signal<GetInTouch>({} as GetInTouch);
  getInTouch = this.#getInTouch.asReadonly();
  #project = signal<Project>({} as Project);
  project = this.#project.asReadonly();
  #plan = signal<Plan>({} as Plan);
  plan = this.#plan.asReadonly();
  #priorities = signal<Priorities>({} as Priorities);
  priorities = this.#priorities.asReadonly();
  #yourRequirements = signal<YourRequirements>({} as YourRequirements);
  yourRequirements = this.#yourRequirements.asReadonly();
  #prepareTheMeal = signal<PrepareTheMeal>({} as PrepareTheMeal);
  prepareTheMeal = this.#prepareTheMeal.asReadonly();
  #diningArea = signal<DiningArea>({} as DiningArea);
  diningArea = this.#diningArea.asReadonly();
  #kitchenOtherActivities = signal<KitchenOtherActivities>({} as KitchenOtherActivities);
  kitchenOtherActivities = this.#kitchenOtherActivities.asReadonly();
  #kitchenStorage = signal<KitchenStorage>({} as KitchenStorage);
  kitchenStorage = this.#kitchenStorage.asReadonly();
  #satellite = signal<Satellite>({} as Satellite);
  satellite = this.#satellite.asReadonly();
  #approach = signal<Approach>({} as Approach);
  approach = this.#approach.asReadonly();

  loadAppInterface(): Observable<boolean> {
    return this.#authService.loadStoreLogged().pipe(
      mergeMap(() => {
        const storeId = this.#authService.storeLogged()?.storeId
          ? String(this.#authService.storeLogged()?.storeId)
          : '';
        return this.#appApiService.read(storeId, { cacheExpirationTime: TEN_HOURS }).pipe(
          tap((appInterface) => {
            LogsService.info(`🟢 Data retrieval from the interface completed successfully.`);
            if (storeId) {
              LogsService.debug(`🤖 App Interface with storeId`, appInterface);
            } else {
              LogsService.debug(`🤖 App Interface`, appInterface);
            }
            this.#appInterface.set(appInterface);
            this.#logo.set(appInterface.logo);
            this.#screenImageLogin.set(appInterface.screenImageLogin);
            this.#customerSearch.set(appInterface.customerSearch);
            this.#householdComposition.set(appInterface.householdComposition);
            this.#menuHeaderPicto.set(appInterface.menuHeaderPicto);
            this.#menuSidebar.set(appInterface.menuSidebar);
            this.#footer.set(appInterface.footer);
            this.#yourProject.set(appInterface.yourProject);
            this.#getInTouch.set(appInterface.getInTouch);
            this.#project.set(appInterface.project);
            this.#plan.set(appInterface.plan);
            this.#priorities.set(appInterface.priorities);
            this.#yourRequirements.set(appInterface.yourRequirements);
            this.#prepareTheMeal.set(appInterface.prepareTheMeal);
            this.#diningArea.set(appInterface.diningArea);
            this.#kitchenOtherActivities.set(appInterface.kitchenOtherActivities);
            this.#kitchenStorage.set(appInterface.kitchenStorage);
            this.#satellite.set(appInterface.satellite);
            this.#approach.set(appInterface.satellite?.approach);
            this.#appInterfaceLoaded.set(true);
          }),
          map((appInterface) => !!appInterface),
          catchError((error) => {
            LogsService.error(`🔴 Unable to retrieve data from the interface`, {
              report: error as unknown,
            });
            return of(false);
          })
        );
      })
    );
  }

  setClassColorActive(classColorActive: string): void {
    this.#classColorActive.set(classColorActive);
  }
}
